<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :name="field"
    :vid="vid"
    :rules="rules">
    <!--Field-->
    <div class="text__field"
         :class="{'errors__field': errors[0]}">
      <!--Prefix icon-->
      <slot name="prefix" />

      <!--Input-->
      <textarea :disabled="disabled"
             :placeholder="placeholder"
             :rows="rows"
             :value="value"
             :maxlength="maxLength"
             :class="{'has__prefix': prefix}"
             @input="handleType($event)"/>
    </div>

    <!--Message Error-->
    <span
      v-if="errors[0]"
      class="errors">
      {{ errors[0] }}
    </span>
  </ValidationProvider>
</template>

<script>
export default {
  name: 'TextAreaField',

  model: {
    prop: 'value',
    event: 'input'
  },

  props: {
    field: {
      type: String,
      default: ''
    },

    rules: {
      type: String,
      default: ''
    },

    vid: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: ''
    },

    value: {
      type: [String, Number],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    prefix: {
      type: Boolean,
      default: false
    },

    maxLength: {
      type: Number,
      default: 524288
    },

    rows: {
      type: Number,
      default: 6
    }
  },

  methods: {
    handleType ($event) {
      this.$emit('input', $event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.text__field {
  position: relative;
  line-height: 1;
  textarea {
    border-radius: 6px;
    width: 100%;
    padding: 16px 14px;
    @include font-size(20px);
    @media #{$info-phone} {
      font-size: 16px;
      padding: 11px 14px;
    }
  }
  textarea::placeholder {
    color: #BDBFAE;
    @media #{$info-phone} {
      font-size: 16px;
    }
  }
  textarea[disabled] {
    background: rgba(255,255,255,.35);
    color: #282828;
  }
  .prefix {
    color: red;
  }
  .has__prefix {
    padding-left: 45px;
  }
}
</style>
